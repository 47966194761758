/* Algemene reset voor body en html */
html, body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
}

/* Nav */
nav {
  background: transparent;
  padding: 1rem;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 20px; /* Verhoog de marge om meer ruimte tussen de kopjes te creëren */
}

nav ul li a {
  color: white;
  text-decoration: none;
  cursor: pointer;
  font-size: 36px;
  font-weight: 700;
}

nav ul li img {
  height: 150px;
}

/* Media query voor mobiel */
@media (max-width: 600px) {
  nav ul li {
    display: none;
  }

  nav ul li:first-child {
    display: block;
  }

  nav ul {
    justify-content: center;
  }

  nav ul li img {
    height: 100px;
    margin-top: -30px; /* Verplaats het logo iets omhoog op mobiele apparaten */
  }
}


/* Hero */
.hero {
  height: 1500px;
  background: url("img/Hero.JPG") no-repeat center center/cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

@media (max-width: 768px) {
  .hero {
    height: 800px; /* Verlaag de hoogte van de hero-sectie op mobiele apparaten */
  }
}

@media (max-width: 480px) {
  .hero {
    height: 600px; /* Verlaag de hoogte van de hero-sectie verder op kleinere schermen */
  }
}


/* Introduction */
.introduction {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-align: center;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  margin: 0;
}

.introduction .intro-text,
.introduction .owners-photo {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
}

.introduction .owners-photo img {
  max-width: 200px;
  border-radius: 50%;
}

/* Owners */
.owners {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #d8eefe;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  margin: 1rem auto;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.owners .owners-text {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 1rem;
}

.owners .owners-photo {
  width: 100%;
  max-width: 100%;
  text-align: center;
  box-sizing: border-box;
}

.owners .owners-photo img {
  max-width: 150px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.owners .owners-photo p {
  margin-top: 1rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1.2rem;
  font-style: italic;
}

/* Galerij */
.gallery {
  text-align: center;
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 1rem;
}

.gallery h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.gallery-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 0;
}

.gallery-images img,
.show-more-img {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  width: calc(25% - 20px); /* Zorgt ervoor dat 4 afbeeldingen in één rij passen */
  height: 200px; /* Zorgt voor consistente hoogte van de afbeeldingen */
  object-fit: cover; /* Zorgt ervoor dat de afbeelding wordt bijgesneden, zodat deze de container vult */
}

.show-more-img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.show-more-img span {
  z-index: 1;
}

@media (max-width: 768px) {
  .gallery-images img,
  .show-more-img {
    width: calc(50% - 20px); /* 2 afbeeldingen naast elkaar op kleinere schermen */
    height: 150px; /* Houdt een consistente hoogte aan op kleinere schermen */
  }
}

@media (max-width: 480px) {
  .gallery-images img,
  .show-more-img {
    width: calc(100% - 20px); /* 1 afbeelding per rij op de kleinste schermen */
    height: 200px; /* Houdt een consistente hoogte aan op mobiele apparaten */
  }
}






/* Informatie */
.location,
.information {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.location h2,
.information h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.information-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 0;
}

.card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: calc(33% - 40px);
  text-align: left;
  box-sizing: border-box;
}

.card-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.card-description p {
  margin: 0;
}

@media (max-width: 768px) {
  .card {
    width: calc(50% - 40px);
  }
}

@media (max-width: 480px) {
  .card {
    width: calc(100% - 40px);
  }
}


/* Voorzieningen (Amenities) */
.amenities {
  text-align: center;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
  box-sizing: border-box;
}

.amenities h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.amenities-list {
  padding: 0;
  list-style: none;
}

.amenities-category {
  margin-bottom: 20px;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.amenities-category h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  padding: 10px 0;
}

.amenities-category ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 10px;
  display: none; /* Standaard verborgen */
}

.amenities-category.active ul {
  display: block; /* Toont de items wanneer de categorie actief is */
}

.amenities-category ul li {
  font-size: 1rem;
  margin-bottom: 5px;
}




/* Reviews */
.reviews {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;

}

.reviews-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
}

.review {
  background: white;
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.review-content {
  flex-grow: 1;
  text-align: center;
  padding-right: 10px;
}

.review .stars {
  color: #ff6347;
}

.slick-dots li button:before {
  color: #ff6347;
}

.slick-prev:before, .slick-next:before {
  color: #ff6347;
}

.slick-slider {
  width: 100%;
  margin: 0 auto;
  max-width: 100vw;
  box-sizing: border-box;
}

.slick-slide {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.slick-slide div {
  outline: none;
}

.slick-prev {
  left: -45px;
  align-items: center;
}

.slick-next {
  right: -45px;
}

/* Calendar */
.calendar {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
}

.booked {
  background: #ff6347 !important; /* Rood voor geboekte data */
  color: white !important;
}

.available {
  background: #4caf50 !important; /* Groen voor beschikbare data */
  color: white !important;
}

.today {
  background: #ffa500 !important; /* Oranje voor vandaag */
  color: white !important;
}

.calendar .rmdp-calendar {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
}

/* Legenda */
.legend {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 500px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 5px;
}

.legend-color.booked {
  background: #ff6347;
}

.legend-color.available {
  background: #4caf50;
}

.legend-color.today {
  background: #ffa500;
}

/* Footer */
footer {
  background: #a51a01;
  color: white;
  padding: 1rem;
  text-align: center;
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
}

footer a {
  color: white;

}

footer a:hover {
  color: lightgray; /* Kleur van de hover  */
}


/* Verwijder horizontale scroll */
* {
  box-sizing: border-box;
  overflow-x: hidden;
}
